.privacy * {
  color: #000;
  font-size: 25px;
}

body.privacy, html.privacy {
  margin: 0;
  padding: 0;
  height: 100%;
}

.privacy small {
  font-size: 15px;
  font-style: italic;
}

.privacy .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.privacy .content {
  height: 150%;
  padding: 100px;
  box-sizing: border-box;
  overflow-y: scroll;
  position: relative;
  background: rgb(255, 255, 255);
}
.privacy .content img {
  width: 3rem;
  margin-bottom: 30px;
}
.privacy .content section {
  padding: 20px;
}/*# sourceMappingURL=privacy.css.map */