@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: "SF Pro", "Inter Tight", sans-serif;
  color: #fef9fc;
  overflow-x: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
* a {
  cursor: pointer;
  transition: transform 0.2s;
}
* a:hover {
  transform: translateY(-2px);
}
* a:active {
  transform: translateY(2px);
}
* button {
  cursor: pointer;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}
.home .ingles {
  position: absolute;
  top: 6%;
  left: 6%;
}
.home .ingles img {
  width: 60px;
}
.home .profile {
  bottom: 0;
  padding-top: 5rem;
  height: 100vh;
  z-index: -2;
}
.home .texto {
  position: absolute;
  font-size: 6vh;
  text-align: center;
  color: #fef9fc;
  transform: translateY(80px);
  font-weight: 700;
  z-index: 2;
}
.home .icones {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
  position: absolute;
  transform: translateY(150px);
}
.home .icones a {
  margin: 1.5vh;
}
.home .icones a img {
  width: 7vh;
  height: 7vh;
}

.sobremim {
  background-image: url("../assets/degrade.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
}
.sobremim .titulo {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.sobremim .informacoes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.sobremim .informacoes .info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  font-weight: 200;
  font-size: 1.3rem;
}
.sobremim .informacoes .info a {
  display: flex;
  font-weight: 200;
  font-size: 1.3rem;
}
.sobremim .informacoes .info a:hover {
  transform: none;
}
.sobremim .informacoes .info img {
  width: 130px;
  height: 130px;
}

.conhecimentos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #fef9fc;
  color: #242424;
  padding: 3rem;
}
.conhecimentos .titulo {
  color: #242424;
  font-size: 4rem;
  font-weight: 700;
}
.conhecimentos .subtitulo {
  color: #242424;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}
.conhecimentos .gridContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 colunas com larguras iguais */
  grid-template-rows: repeat(3, 1fr); /* 3 linhas com alturas iguais */
  gap: 10px; /* Espaçamento entre os itens */
  padding: 1.5rem;
  overflow: hidden;
}
.conhecimentos .gridContainer button {
  height: 150px;
  width: 150px;
}
.conhecimentos .gridContainer img {
  height: 75px;
  width: 75px;
}
.conhecimentos .conhecimento {
  border-radius: 50%;
  background-image: url("../assets/botao.webp");
  background-size: cover;
  flex: 1; /* BENDITO */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}
.conhecimentos .conhecimento:hover {
  transform: scale(105%);
  box-shadow: 0 4px 7.5px rgba(0, 0, 0, 0.2);
}
.conhecimentos .conhecimento:active {
  transform: scale(100%);
  box-shadow: none;
}
.conhecimentos .conhecimento-img {
  width: 93px;
  height: 93px;
  -o-object-fit: contain;
     object-fit: contain;
}

.projetos {
  background-image: url("../assets/degrade2.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
}
.projetos .titulo {
  font-size: 4rem;
  font-weight: 700;
}
.projetos .subtitulo {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}
.projetos .icones {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.projetos .icones .icone {
  width: 151.3px;
  height: 85px;
}

.projeto img {
  cursor: pointer;
}

.contato {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.contato .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.contato .card .inicio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.contato .card .inicio .imagem img {
  width: 223px;
  height: 223px;
}
.contato .card .inicio .texto {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
}
.contato .card .inicio .texto .titulo {
  font-size: 5rem;
  font-weight: 700;
  color: #242424;
  text-align: left;
}
.contato .card .inicio .texto .subtitulo {
  font-size: 3.6rem;
  font-weight: 200;
  color: #242424;
  text-align: left;
  font-style: italic;
}
.contato .card .final {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contato .card .final .icones {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.contato .card .final .icones img {
  width: 48px;
  height: 48px;
  margin-left: 1.3rem;
  margin-right: 1.3rem;
}
.contato .card .final .cv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contato .card .final .cv a {
  padding-left: 8px;
  padding-right: 8px;
}
.contato .card .final .cv a img {
  width: 90px;
  height: 50.4px;
}
.contato .footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #242424;
  font-size: 1.5rem;
}
.contato .footer .nonstop {
  transition: 0.5s;
}
.contato .footer .nonstop:hover {
  color: #242424;
}

.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(36, 36, 36, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-border {
  background-image: url("../assets/degrade2.png");
  background-size: cover;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-border .modal-content {
  background-color: #fef9fc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #242424;
  padding: 5rem;
}
.modal-border .modal-content .header {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
}
.modal-border .modal-content .header .imagem {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  overflow-y: hidden;
}
.modal-border .modal-content .header .titulo {
  font-size: 5rem;
  font-weight: 700;
  color: #242424;
  overflow-y: hidden;
}
.modal-border .modal-content .nivel-de-conhecimento {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
}
.modal-border .modal-content .nivel-de-conhecimento .estrelas {
  width: 145px;
  height: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}
.modal-border .modal-content .nivel-de-conhecimento .titulo {
  font-size: 3rem;
  color: #242424;
  overflow-y: hidden;
}
.modal-border .modal-content .certificados {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
}
.modal-border .modal-content .certificados .titulo {
  font-size: 3rem;
  color: #242424;
}
.modal-border .modal-content .certificados ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-border .modal-content .certificados ul li a {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #242424;
  padding: 0.5rem;
}
.modal-border .modal-content .projetos-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
}
.modal-border .modal-content .projetos-modal .titulo {
  font-size: 3rem;
  color: #242424;
}
.modal-border .modal-content .projetos-modal ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-border .modal-content .projetos-modal ul li a {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #242424;
  padding: 0.5rem;
}

@media only screen and (max-width: 900px) {
  * a:hover {
    transform: none;
  }
  .home {
    overflow-x: hidden;
  }
  .home .profile {
    bottom: 0;
    padding-top: 0;
    z-index: -2;
  }
  .home .texto {
    font-size: 4rem;
  }
  .sobremim {
    background-image: url("../assets/degrade.png");
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;
  }
  .sobremim .titulo {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .sobremim .informacoes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sobremim .informacoes .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    margin-bottom: 5vw;
    text-align: center;
    font-weight: 200;
  }
  .sobremim .informacoes .info img {
    width: 130px;
    height: 130px;
  }
  .conhecimentos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #fef9fc;
    color: #fef9fc;
    padding: 3rem;
  }
  .conhecimentos .titulo {
    color: #242424;
    font-size: 4rem;
    font-weight: 700;
  }
  .conhecimentos .subtitulo {
    color: #242424;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .conhecimentos .gridContainer {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 5 colunas com larguras iguais */
    grid-template-rows: repeat(5, 1fr); /* 3 linhas com alturas iguais */
    gap: 10px; /* Espaçamento entre os itens */
    margin: 0 auto; /* Opcional: centralize o grid na tela */
    padding-right: 2px;
    padding-left: 2px;
  }
  .conhecimentos .gridContainer button {
    height: 13vh;
    width: 13vh;
  }
  .conhecimentos .gridContainer img {
    height: 7vh;
    width: 7vh;
  }
  .conhecimentos .conhecimento {
    border-radius: 50%;
    background-image: url("../assets/botao.webp");
    background-size: cover;
    flex: 1; /* BENDITO */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
  }
  .conhecimentos .conhecimento:hover {
    transform: scale(100%);
    box-shadow: 0;
  }
  .conhecimentos .conhecimento-img {
    width: 93px;
    height: 93px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .projetos {
    background-image: url("../assets/degrade2.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;
  }
  .projetos .titulo {
    font-size: 4rem;
    font-weight: 700;
  }
  .projetos .subtitulo {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .projetos .icones {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.7rem;
    width: 70%;
  }
  .projetos .icones .projeto {
    width: calc(50% - 0.5rem);
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
  }
  .projetos .icones .icone {
    width: 130px;
    height: 85px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .contato {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .contato .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .contato .card .inicio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }
  .contato .card .inicio .imagem {
    margin: 0;
  }
  .contato .card .inicio .imagem img {
    width: 223px;
    height: 223px;
  }
  .contato .card .inicio .texto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .contato .card .inicio .texto .titulo {
    font-size: 3rem;
    font-weight: 700;
    color: #242424;
  }
  .contato .card .inicio .texto .subtitulo {
    font-size: 2rem;
    font-weight: 200;
    color: #242424;
  }
  .contato .card .final {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
  }
  .contato .card .final .icones {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    z-index: 1;
    margin-bottom: 1rem;
  }
  .contato .card .final .icones img {
    width: 48px;
    height: 48px;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
  }
  .contato .card .final .cv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contato .card .final .cv a {
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
    width: 90px;
    height: 50.4px;
    margin-bottom: 16px;
  }
  .contato .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #242424;
    font-size: 1.2rem;
  }
  .contato .footer .nonstop {
    transition: 0.5s;
  }
  .contato .footer .nonstop:hover {
    color: #242424;
  }
  .modal-border {
    background-image: url("../assets/degrade2.png");
    background-size: cover;
    padding: 20px;
    border-radius: 15px;
    height: 50rem;
    width: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-border .modal-content {
    height: 40rem;
    width: 30rem;
    background-color: #fef9fc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #242424;
    padding: 5rem;
  }
  .modal-border .modal-content .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }
  .modal-border .modal-content .header .imagem {
    width: 100px;
    height: 100px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .modal-border .modal-content .header .titulo {
    font-size: 2rem;
    font-weight: 700;
    color: #242424;
  }
  .modal-border .modal-content .nivel-de-conhecimento {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }
  .modal-border .modal-content .nivel-de-conhecimento .estrelas {
    width: 145px;
    height: 25px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .modal-border .modal-content .nivel-de-conhecimento .titulo {
    font-size: 1.5rem;
    color: #242424;
  }
  .modal-border .modal-content .certificados {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }
  .modal-border .modal-content .certificados .titulo {
    overflow-y: hidden;
    font-size: 1.6rem;
    color: #242424;
  }
  .modal-border .modal-content .certificados ul {
    overflow-y: hidden;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-border .modal-content .certificados ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #242424;
    padding: 0.5rem;
    text-align: center;
  }
  .modal-border .modal-content .projetos-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }
  .modal-border .modal-content .projetos-modal .titulo {
    overflow-y: hidden;
    font-size: 1.6rem;
    color: #242424;
  }
  .modal-border .modal-content .projetos-modal ul {
    overflow-y: hidden;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .modal-border .modal-content .projetos-modal ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #242424;
    overflow-y: hidden;
  }
  .modal-border .modal-content .projetos-modal ul li a {
    padding: 0.5rem;
  }
}/*# sourceMappingURL=style.css.map */