.rtn-home {
  display: flex;
  justify-content: center;
}
.rtn-home img {
  width: 748px;
  height: 655px;
  padding-top: 100px;
}
@media (max-width: 900px) {
  .rtn-home img {
    width: 70%;
    height: auto;
    padding-top: 100px;
  }
}

.rtn-1 {
  background: linear-gradient(to right, #FF6960, #FA4439);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .rtn-1 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
.rtn-1 .rtn-content {
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-1 .rtn-content {
    padding-bottom: 0px;
  }
}
.rtn-1 .rtn-content .rtntitle {
  font-size: 4em;
  font-weight: 500;
}
.rtn-1 .rtn-content .rtnsubtitle {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 10px;
}
.rtn-1 .rtn-content .rtntext {
  font-size: 2em;
  font-weight: 200;
}
.rtn-1 .rtn-content img {
  width: 20%;
  padding: 0;
  padding-top: 10px;
}
.rtn-1 img {
  width: 80%;
  height: auto;
  max-width: 80%;
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-1 img {
    padding-top: 10px;
  }
}

.rtn-2 {
  background: linear-gradient(to right, #2D2D2D, #000000);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .rtn-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
.rtn-2 .rtn-content {
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-2 .rtn-content {
    padding-top: 0px;
  }
}
.rtn-2 .rtn-content .rtntitle {
  font-size: 4em;
  font-weight: 500;
}
.rtn-2 .rtn-content .rtnsubtitle {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 10px;
}
.rtn-2 .rtn-content .rtntext {
  font-size: 2em;
  font-weight: 200;
}
.rtn-2 .rtn-content img {
  width: 20%;
  padding: 0;
  padding-top: 10px;
}
.rtn-2 img {
  width: 80%;
  height: auto;
  max-width: 80%;
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-2 img {
    padding-bottom: 0px;
  }
}

.rtn-3 {
  background: linear-gradient(to right, #339FFF, #0A84FD);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .rtn-3 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
.rtn-3 .rtn-content {
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-3 .rtn-content {
    padding-bottom: 0px;
  }
}
.rtn-3 .rtn-content .rtntitle {
  font-size: 4em;
  font-weight: 500;
}
.rtn-3 .rtn-content .rtnsubtitle {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 10px;
}
.rtn-3 .rtn-content .rtntext {
  font-size: 2em;
  font-weight: 200;
}
.rtn-3 .rtn-content img {
  width: 20%;
  padding: 0;
  padding-top: 10px;
}
.rtn-3 img {
  width: 80%;
  height: auto;
  max-width: 80%;
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-3 img {
    padding-top: 0px;
  }
}

.rtn-4 {
  background: linear-gradient(to right, #5ADF7B, #2FCD56);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .rtn-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
.rtn-4 .rtn-content {
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-4 .rtn-content {
    padding-top: 0px;
  }
}
.rtn-4 .rtn-content .rtntitle {
  font-size: 4em;
  font-weight: 500;
}
.rtn-4 .rtn-content .rtnsubtitle {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: 10px;
}
.rtn-4 .rtn-content .rtntext {
  font-size: 2em;
  font-weight: 200;
}
.rtn-4 .rtn-content img {
  width: 20%;
  padding: 0;
  padding-top: 10px;
}
.rtn-4 img {
  width: 80%;
  height: auto;
  max-width: 80%;
  padding: 50px;
}
@media (max-width: 768px) {
  .rtn-4 img {
    padding-bottom: 0px;
  }
}

.rtn-5 {
  display: flex;
  justify-content: center;
  align-items: end;
}
.rtn-5 img {
  width: 40%;
  height: auto;
  max-width: 40%;
  padding: 50px;
  padding-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.footer a {
  text-decoration: none;
  color: #000000;
}/*# sourceMappingURL=rtn.css.map */