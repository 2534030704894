$rosa: #f29dc4;
$branco: #fef9fc;
$azul: #56baeb;
$preto: #242424;
$roxo: #27204d;

@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

* {
    margin: 0;
    padding: 0;
    font-size: 10px;
    font-family:  "SF Pro", "Inter Tight", sans-serif;
    color: $branco;
    overflow-x: hidden;
    a {
        cursor: pointer;
        transition: transform .2s;
        &:hover {
            transform: translateY(-2px);
        }
        &:active {
            transform: translateY(2px);
        }
    }
    button {
        cursor: pointer;
    }
    user-select: none
}

.home {
    @include flex();
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;

    .ingles {
        position: absolute;
        top: 6%;
        left: 6%;
        img {
            width: 60px;
        }
    }

    .profile {
        bottom: 0;
        padding-top: 5rem;
        height: 100vh;
        z-index: -2;
    }

    .texto {
        position: absolute;
        font-size: 6vh;
        text-align: center;
        color: $branco;
        transform: translateY(80px);
        font-weight: 700;
        z-index: 2;
    }

    .icones {
        @include flex();
        flex-direction: row;
        z-index: 1;
        position: absolute;
        transform: translateY(150px);
        a {
            margin: 1.5vh;
            img {
                width: 7vh;
                height: 7vh;
            }
        }
    }
}

.sobremim {
    background-image: url("../assets/degrade.png");
    background-size: cover;
    @include flex();
    flex-direction: column;
    padding: 3rem;
    .titulo {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    .informacoes {
        @include flex();
        flex-direction: row;
        .info {
            @include flex();
            flex-direction: column;
            width: 150px;
            margin-left: 5vw;
            margin-right: 5vw;
            text-align: center;
            font-weight: 200;
            font-size: 1.3rem;
            a {
                display: flex;
                font-weight: 200;
                font-size: 1.3rem;
                &:hover {
                    transform: none;
                }
            }
            img {
                width: 130px;
                height: 130px;
            }
        }
    }
}

.conhecimentos {
    @include flex();
    flex-direction: column;
    text-align: center;
    background-color: $branco;
    color: $preto;
    padding: 3rem;
    .titulo {
        color: $preto;
        font-size: 4rem;
        font-weight: 700;
    }
    .subtitulo {
        color: $preto;
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 2rem;
    }
    .gridContainer {
        @include flex();
        display: grid;
        grid-template-columns: repeat(5, 1fr); /* 5 colunas com larguras iguais */
        grid-template-rows: repeat(3, 1fr); /* 3 linhas com alturas iguais */
        gap: 10px; /* Espaçamento entre os itens */
        padding: 1.5rem;
        overflow: hidden;
    }
    .gridContainer button {
        height: 150px;
        width: 150px;
    }
    .gridContainer img {
        height: 75px;
        width: 75px;
    }
    .conhecimento {
        border-radius: 50%;
        background-image: url("../assets/botao.webp");
        background-size: cover;
        flex: 1; /* BENDITO */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: none;
        cursor: pointer;
        transition: transform 0.2s, box-shadow 0.2s;
        &:hover {
            transform: scale(105%);
            box-shadow: 0 4px 7.5px rgba(#000, 0.2);
        }
        &:active {
            transform: scale(100%);
            box-shadow: none;
        }
    } 
    .conhecimento-img {
        width: 93px;
        height: 93px;
        object-fit: contain;
    }
}

.projetos {
    background-image: url("../assets/degrade2.png");
    background-size: cover; 
    @include flex();
    flex-direction: column;
    padding: 3rem;
    .titulo {
        font-size: 4rem;
        font-weight: 700;
    }
    .subtitulo {
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 2rem;
    }
    .icones {
        @include flex();
        flex-direction: row;
        .icone {
            width: 178px * 0.85;
            height: 100px * 0.85;
        }
    }
}

.projeto {
    img {
        cursor: pointer;
    }
}

.contato {
    @include flex();
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .card {
        @include flex();
        flex-direction: row;
        .inicio {
            @include flex();
            flex-direction: row;
            .imagem {
                img {
                    width: 223px;
                    height: 223px;
                }
            }
            .texto {
                @include flex();
                flex-direction: column;
                align-items: flex-start;
                padding: 2rem;
                .titulo {
                    font-size: 5rem;
                    font-weight: 700;
                    color: $preto;
                    text-align: left;
                }
                .subtitulo {
                    font-size: 3.6rem;
                    font-weight: 200;
                    color: $preto;
                    text-align: left;
                    font-style: italic;
                }
            }
        }
        .final {
            @include flex();
            flex-direction: column;
            .icones {    
                @include flex();
                flex-direction: row;
                z-index: 1;
                padding-top: 1rem;
                img { 
                    width: 48px;
                    height: 48px;
                    margin-left: 1.3rem;
                    margin-right: 1.3rem;
                }
                margin-bottom: 1rem;
            }
            .cv {
                @include flex();
                a {
                    padding-left: 8px;
                    padding-right: 8px;
                    img {
                        width: 90px;
                        height: 50.4px;
                    }
                }
            }
        }
    }
    .footer {
        @include flex();
        flex-direction: column;
        color: $preto;
        font-size: 1.5rem;
        .nonstop {
            transition: .5s;
        }
        .nonstop:hover {
            color: $preto;
        }
    }
}

.modal {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba($preto, 60%);
    @include flex();
}

.modal-border {
    background-image: url("../assets/degrade2.png");
    background-size: cover;
    padding: 20px;
    border-radius: 15px;
    @include flex();
    .modal-content {
        background-color: rgba($branco, 100%);
        border-radius: 15px;
        @include flex();
        flex-direction: column;
        color: $preto;
        padding: 5rem;
        .header {
            overflow-y: hidden;
            @include flex();
            flex-direction: column;
            margin: 1rem;
            .imagem {
                width: 100px;
                height: 100px;
                object-fit: contain;
                overflow-y: hidden;
            }
            .titulo {
                font-size: 5rem;
                font-weight: 700;
                color: $preto;
                overflow-y: hidden;
            }
        }
        .nivel-de-conhecimento {
            @include flex();
            flex-direction: column;
            margin: 1rem;
            .estrelas {
                width: 145px;
                height: 25px;
                object-fit: contain;
            }
            .titulo {
                font-size: 3rem;
                color: $preto;
                overflow-y: hidden;
            }
        }
        .certificados {
            @include flex();
            flex-direction: column;
            margin: 1rem;
            .titulo {
                font-size: 3rem;
                color: $preto;
            }
            ul {
                list-style: none;
                @include flex();
                li a {
                    font-size: 1.5rem;
                    @include flex();
                    color: $preto;
                    padding: .5rem;
                }
            }
        }
        .projetos-modal {
            @include flex();
            flex-direction: column;
            margin: 1rem;
            .titulo {
                font-size: 3rem;
                color: $preto;
            }
            ul {
                list-style: none;
                @include flex();
                li a {
                    font-size: 1.5rem;
                    @include flex();
                    color: $preto;
                    padding: .5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) { 
    * {
        a:hover {
            transform: none;
        }
    }
    .home {
        overflow-x: hidden;
        .profile {
            bottom: 0;
            padding-top: 0;
            z-index: -2;
        } 
        .texto {
            font-size: 4rem;
        }
    }
    .sobremim {
        background-image: url("../assets/degrade.png");
        background-size: contain;
        @include flex();
        flex-direction: column;
        padding: 3rem;
        .titulo {
            font-size: 4rem;
            font-weight: 700;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
        .informacoes {
            @include flex();
            flex-direction: column;
            .info {
                @include flex();
                flex-direction: column;
                width: 150px;
                margin-bottom: 5vw;
                text-align: center;
                font-weight: 200;
                img {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }
    .conhecimentos {
        @include flex();
        flex-direction: column;
        text-align: center;
        background-color: $branco;
        color: $branco;
        padding: 3rem;
        .titulo {
            color: $preto;
            font-size: 4rem;
            font-weight: 700;
        }
        .subtitulo {
            color: $preto;
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 2rem;
        }
        .gridContainer {
            align-items: center;
            justify-content: center;
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* 5 colunas com larguras iguais */
            grid-template-rows: repeat(5, 1fr); /* 3 linhas com alturas iguais */
            gap: 10px; /* Espaçamento entre os itens */
            margin: 0 auto; /* Opcional: centralize o grid na tela */
            padding-right: 2px;
            padding-left: 2px;
        }  
        .gridContainer button {
            height: 13vh;
            width: 13vh;
        }
        .gridContainer img {
            height: 7vh;
            width: 7vh;
        }
        .conhecimento {
            border-radius: 50%;
            background-image: url("../assets/botao.webp");
            background-size: cover;
            flex: 1; /* BENDITO */
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: none;
            &:hover {
                transform: scale(100%);
                box-shadow: 0;
            }
        }
          
          .conhecimento-img {
            width: 93px;
            height: 93px;
            object-fit: contain;
        }
    }
    .projetos {
        background-image: url("../assets/degrade2.png");
        background-size: cover;
        @include flex();
        flex-direction: column;
        padding: 3rem;
        .titulo {
            font-size: 4rem;
            font-weight: 700;
        }
        .subtitulo {
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 2rem;
        }
        .icones {
            @include flex();
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0.7rem; 
            width: 70%;
            .projeto {
                width: calc(50% - 0.5rem); // metade da largura com um pequeno ajuste para o gap
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 1rem;
            }
            .icone {
                width: 130px;
                height: 85px;
                object-fit: contain
            }
        }
    }    
    .contato {
        @include flex();
        flex-direction: column;
        padding-top: 8rem;
        padding-bottom: 8rem;
        .card {
            @include flex();
            flex-direction: column;
            .inicio {
                @include flex();
                flex-direction: column;
                margin: 1rem;
                .imagem {
                    margin: 0;
                    img {
                        width: 223px;
                        height: 223px;
                    }
                }
                .texto {
                    @include flex();
                    flex-direction: column;
                    margin-top: 1rem !important;
                    margin-left: 1rem !important;
                    margin-right: 1rem !important;
                    .titulo {
                        font-size: 3rem;
                        font-weight: 700;
                        color: $preto;
                    }
                    .subtitulo {
                        font-size: 2rem;
                        font-weight: 200;
                        color: $preto;
                    }
                }
            }
            .final {
                @include flex();
                flex-direction: column;
                margin-top: 0;
                .icones {
                    @include flex();
                    flex-direction: row;
                    z-index: 1;
                    img { 
                        width: 48px;
                        height: 48px;
                        margin-left: 1.3rem;
                        margin-right: 1.3rem;
                    }
                    margin-bottom: 1rem;
                }
                .cv {
                    @include flex();
                    a {
                        object-fit: cover;
                        overflow: hidden;
                        width: 90px;
                        height: 50.4px;
                        margin-bottom: 16px;
                    }
                }
            }
        }
        .footer {
            @include flex();
            flex-direction: column;
            color: $preto;
            font-size: 1.2rem;
            .nonstop {
                transition: .5s;
            }
            .nonstop:hover {
                color: $preto;
            }
        }
    }
    .modal-border {
        background-image: url("../assets/degrade2.png");
        background-size: cover;
        padding: 20px;
        border-radius: 15px;
        height: 50rem;
        width: 35rem;
        @include flex();
        .modal-content {
            height: 40rem;
            width: 30rem;
            background-color: rgba($branco, 100%);
            border-radius: 15px;
            @include flex();
            flex-direction: column;
            color: $preto;
            padding: 5rem;
            .header {
                @include flex();
                flex-direction: column;
                margin: 1rem;
                .imagem {
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                }
                .titulo {
                    font-size: 2rem;
                    font-weight: 700;
                    color: $preto;
                }
            }
            .nivel-de-conhecimento {
                @include flex();
                flex-direction: column;
                margin: 1rem;
                .estrelas {
                    width: 145px;
                    height: 25px;
                    object-fit: contain;
                }
                .titulo {
                    font-size: 1.5rem;
                    color: $preto;
                }
            }
            .certificados {
                @include flex();
                flex-direction: column;
                margin: 1rem;
                .titulo {
                    overflow-y: hidden;
                    font-size: 1.6rem;
                    color: $preto;
                }
                ul {
                    overflow-y: hidden;
                    list-style: none;
                    @include flex();
                    li a {
                        @include flex();
                        color: $preto;
                        padding: .5rem;
                        text-align: center;
                    }
                }
            }
            .projetos-modal {
                @include flex();
                flex-direction: column;
                margin: 1rem;
                .titulo {
                    overflow-y: hidden;
                    font-size: 1.6rem;
                    color: $preto;
                }
                ul {
                    overflow-y: hidden;
                    list-style: none;
                    @include flex();
                    flex-direction: column;
                    li {
                        @include flex();
                        text-align: center;
                        color: $preto;
                        overflow-y: hidden;
                        a {
                            padding: .5rem;
                        }
                    }
                }
            }
        }
    }
}